.footer {
  background-color: #1F1F26;
  width: 100%;
  padding-top: 60px;
  margin-top: auto;
}

.logo-and-name {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 60px;
  width: 60px;
}

.name-img {
  height: 24px;
  margin-left: 8px;
}

.description-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 14px;
  margin-bottom: 36px;
}

.category-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin-top: 18px;
  text-align: left;
}

.category-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  margin-top: 20px;
  text-align: left;
}

.line-separator {
  border: 1px solid #4F4F4F;
  margin-top: 80px;
}

.copyright {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 12px;
  margin-bottom: 40px;
}

a {
  color: inherit;
}

@media (max-width: 992px) {
  .footer {
    padding-top: 40px;
  }

  .category-title {
    font-size: 20px;
  }

  .category-item {
    font-size: 16px;
    line-height: 19px;
  }
  
  .platform-text {
    width: 260px;
    text-align: left;
  }

}

#logo {
	height: 64px;
}

.App-header {
	height: 64px;
}

.logo-column {
	display: flex;
	justify-content: center;
	align-items: center;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
.App {
  background-color: #F9FCFF;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  height: 100%;
}


#logo {
  height: 64px;
}

.App-header {
  height: 64px;
}

.logo-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} 
@media (min-width: 576px) {
  .container {
      width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
      width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
      width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}